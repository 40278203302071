<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/myworld.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar color="#f3bc00" flat>
            <v-toolbar-title class="text-white">{{ $t('generic.lang_myWorldTurnoverInterface') }}</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="myWorld_dealerUserName" :disabled="loading" outlined dense :label="$t('settings.lang_dealerUsername')"></v-text-field>
                <v-text-field v-model="myWorld_dealerNumber" :disabled="loading" outlined dense :label="$t('settings.lang_dealerNumber')"></v-text-field>
                <v-text-field v-model="myWorld_secret" :disabled="loading" type="password" outlined dense :label="$t('settings.lang_apiSecret')"></v-text-field>
                <v-text-field v-model="myWorld_dealerToken" :disabled="loading" type="password" outlined dense :label="$t('settings.lang_merchantTokens')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "MyWorldSettings",
  data() {
    return {
      loading: false,
      myWorld_dealerUserName: "",
      myWorld_dealerNumber: "",
      myWorld_secret: "",
      myWorld_dealerToken: ""
    }
  },
  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.MYWORLD.GET).then((res) => {
        if(res.data.status==='success') {
          this.myWorld_dealerUserName = res.data.myWorld_dealerUserName;
          this.myWorld_dealerNumber = res.data.myWorld_dealerNumber;
          this.myWorld_secret = res.data.myWorld_secret;
          this.myWorld_dealerToken = res.data.myWorld_dealerToken;
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.MYWORLD.UPDATE, {
        myWorld_dealerUserName: this.myWorld_dealerUserName,
        myWorld_dealerNumber: this.myWorld_dealerNumber,
        myWorld_secret: this.myWorld_secret,
        myWorld_dealerToken: this.myWorld_dealerToken,
      }).then((res) => {
        if(res.data.status==='SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }

}
</script>